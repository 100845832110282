export const environment = {
    googleTrackingId: "UA-110030893-1",
    apiUrl: "https://muvapi.muvtravel.com/",
    prodUrl: "https://muvtravel.com",
    captchaKey : "6Lcelm0UAAAAAGNIh7hkACU0IUzCFXtwRTU33b3h",
    gtmId:"GTM-M9LPPBF",
    pricelineApiUrl: "http://api-sandbox.rezserver.com/",
    pricelineRefId: "8660",
    pricelineApiKey: "ce7272cb256e1c225bb8e55ddc64c0ff",
    stripeKey:"sk_test_fz8Xl9xs8sUhM3uOqLUVtJ7E00FeZoYW5h",
    stripePublic:"pk_test_Vd5fdRAcEwF4HLvjul8EjV7y00udaO3zJU",
    blackPageUrl: "https://muv.travel/",
    hjid:3614563,
    hjsv:6,
    production: true,
    isBlackPage: false, 
}
// https://muvapi.muvtravel.com/ ---prod